import { SUBSCRIPTION_SERVICE_URL } from "./Env.js";

export type RequestMethod = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

/**
 * Trigger request with proper CSRF token
 */
export const csrfRequest = async (url: string, method: RequestMethod, body?: object): Promise<any> => {
  url = SUBSCRIPTION_SERVICE_URL + url;

  const response = await fetch(url, {
    method: "OPTIONS",
    credentials: "include",
  });

  const csrfToken = response.headers.get("x-csrf-token");

  const reqParams: RequestInit = {
    method,
    headers: {
      "x-csrf-token": csrfToken,
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  if (body) {
    reqParams.body = JSON.stringify(body);
  }

  // Extra work necessary to handle throwing in error in case of 403 or 404
  return new Promise((resolve, reject) => {
    fetch(url, reqParams)
      .then(async (res) => {
        const resBody = await res.json();
        const successCodes = [200, 201];
        const { status, statusText } = res;
        if (!successCodes.includes(status)) {
          const err = {
            status,
            statusText,
            message: resBody.message,
            url,
          };
          reject(err);
        } else {
          resolve(resBody);
        }
      })
      .catch(reject);
  });
};
